



















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import type { EmailSignUpConfirmation } from '@amplience/types';

export default defineComponent({
  name: 'EmailSignUpSuccess',
  props: {
    confirmation: {
      type: Object as PropType<EmailSignUpConfirmation>,
      default: () => ({
        title: 'Thank you for subscribing to our emails',
        description: '',
      }),
    },
    email: {
      type: [String, null],
      default: 'your email',
    },
    sendEmailText: {
      type: String,
      required: true,
    },
  },
})
