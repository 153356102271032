




































import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import type { EmailSignUpMarketingComms, EmailSignUpConfirmation, EmailSignUpPrivacyPolicy } from '@amplience/types';
import type { UseNewsletterUpdateSubscriptionParams } from '~/composables';
import { SubscriptionStatusesEnum, useNewsletter } from '~/composables';
import EmailSignUpForm from '@amplience/components/molecules/EmailSignUpForm.vue';
import EmailSignUpSuccess from '@amplience/components/molecules/EmailSignUpSuccess.vue';

export default defineComponent({
  name: 'EmailSignUp',
  components: {
    EmailSignUpForm,
    EmailSignUpSuccess,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    privacyPolicy: {
      type: Object as PropType<EmailSignUpPrivacyPolicy>,
      default: () => ({
        richText: null,
      }),
    },
    marketingComms: {
      type: Object as PropType<EmailSignUpMarketingComms>,
      default: () => ({
        required: false,
        disabled: true,
        richText: null,
      }),
    },
    confirmation: {
      type: Object as PropType<EmailSignUpConfirmation>,
      default: () => ({
        title: 'Thank you for subscribing to our emails',
        description: '',
      }),
    },
    id: {
      type: String,
      default: 'email-sign-up',
    },
    confirmationEmailText: {
      type: String,
      default: 'A confirmation email has been sent to',
    },
  },
  setup() {
    const { subscribeEmailToNewsletter, loading, error } = useNewsletter();
    const email = ref<string | null>(null);
    const status = ref<SubscriptionStatusesEnum | null>(null);

    const submitForm = async (form: UseNewsletterUpdateSubscriptionParams) => {
      status.value = await subscribeEmailToNewsletter({ ...form });
      email.value = form.email;
    }

    return {
      loading,
      email,
      isSubscribed: computed(() => status.value === SubscriptionStatusesEnum.Subscribed),
      error: computed(() => error.value.subscribeEmailToNewsletter),
      submitForm,
    }
  }
});
