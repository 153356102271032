
























































































































import { computed, defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { SfButton, SfLoader } from '@storefront-ui/vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import type { EmailSignUpForm, EmailSignUpMarketingComms, EmailSignUpPrivacyPolicy } from '@amplience/types';
import Checkbox from '@theme/components/form/Checkbox.vue';
import TextField from '@theme/components/form/TextField.vue';
import type { ValidationInterface } from '@theme/components/form/types';
import TermsLink from '@theme/components/atoms/TermsLink.vue';

export default defineComponent({
  name: 'EmailSignUpForm',
  components: {
    SfButton,
    SfLoader,
    TermsLink,
    TextField,
    Checkbox,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    privacyPolicy: {
      type: Object as PropType<EmailSignUpPrivacyPolicy>,
      default: () => ({
        richText: null,
      }),
    },
    marketingComms: {
      type: Object as PropType<EmailSignUpMarketingComms>,
      default: () => ({
        required: false,
        disabled: true,
        richText: null,
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [Array, null] as PropType<string[] | null>,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const form = ref<EmailSignUpForm>({
      email: '',
      privacy_policy: false,
      marketing_comms: false,
    });
    const isSubscribed = ref<boolean>(false);
    const hasMarketingRichTextContent = computed<boolean>(() => !!(props.marketingComms.richText?.[0]?.data));
    const hasPrivacyPolicyRichTextContent = computed<boolean>(() => !!(props.privacyPolicy?.richText?.[0]?.data));

    const submitForm = async (validateWithInfo) => {
      const formState: ValidationInterface = await validateWithInfo();

      if (formState.isValid) {
        emit('submit-form', form.value);
      }
    };

    watch(() => props.error, () => {
      if (props.error) {
        isSubscribed.value = true;
      }
    })

    return {
      form,
      isSubscribed,
      hasMarketingRichTextContent,
      hasPrivacyPolicyRichTextContent,
      submitForm,
    }
  }
})
