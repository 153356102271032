import { render, staticRenderFns } from "./EmailSignUpSuccess.vue?vue&type=template&id=2668ce32&scoped=true"
import script from "./EmailSignUpSuccess.vue?vue&type=script&lang=ts"
export * from "./EmailSignUpSuccess.vue?vue&type=script&lang=ts"
import style0 from "./EmailSignUpSuccess.vue?vue&type=style&index=0&id=2668ce32&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2668ce32",
  null
  
)

export default component.exports